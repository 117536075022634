
























import { Component, Watch, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import VueRouter from 'vue-router'
import LQMovieLoadable, { LQMovieUrls } from '@/mixins/utils/LQMovieLoadable'
import WebApi, { MovieData } from '@/mixins/drillsv3/WebApi'
import HistoryApi, { ResponseResultMovie } from '@/mixins/drillsv3/HistoryApi'

@Component({
  components: {
    ButtonBase,
  },
  head: {
    script: [{ type: 'text/javascript', src: '/js/oneTagPlayer.js', body: true }],
    link: [{ rel: 'stylesheet', href: '/css/oneTagPlayer.css' }],
  },
})
export default class IntroductionVideo extends Mixins(LQMovieLoadable, WebApi, HistoryApi) {
  private curriculumSUnitName = ''
  private movies: MovieData[] = []
  private selectedMovie: MovieData | null = null
  private resultMovie: ResponseResultMovie | null = null

  private curriculumSUnitId = Number(this.$route.params.curriculumSUnitId)
  private historyBackUrl = this.$route.query.historyBackUrl as string
  // selectedMovie.movieNameの変更時、各画質のURLを取得し、ここに保持する
  private selectedMovieUrls: LQMovieUrls = {}

  // FIXME: GDLS-1923 AI学習モード限定になっているので、必要になった場合は修正
  // AI学習関連の定義値
  private classModeCode = this.$route.params.classMode || 'AI'

  public async mounted() {
    Vue.prototype.$logger.log('-- IntroductionVideo mounted')

    window.addEventListener('unhandledrejection', this.detectError, true)
    await this.loadDatas()
  }

  private beforeDestroy() {
    window.removeEventListener('unhandledrejection', this.detectError)
  }

  public beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    Vue.prototype.$logger.info('-- IntroductionVideo beforeRouteUpdate')

    next()
  }

  private detectError(event: any): void {
    const message = event.reason.message
    if (
      message === 'The operation was aborted.' ||
      message.includes('The play() request was interrupted by a call to pause().')
    ) {
      alert(
        '動画の読み込みに失敗したか読み込み中の誤操作を検知しました。お手数ですが再度読み込みを行い動画の表示を待ってから再生をお願いします。'
      )

      // 後続のhandleError.tsでキャッチしているエラーイベントのリスナーをキャンセルする
      event.stopImmediatePropagation()
    }
  }

  private async loadDatas() {
    Vue.prototype.$loading.start()
    const explainMovies = await this.getIntroductionMovies(this.curriculumSUnitId)
    this.curriculumSUnitName = explainMovies.curriculumSUnitName
    this.movies = explainMovies.movies
    const movieIdSelected = +this.$route.query.movieId

    // 変則的だがselectedMovie内でもloadingを行うため一度完了にする
    Vue.prototype.$loading.complete()
    await this.setSelectedMovie(movieIdSelected || this.movies[0].movieId)
    Vue.prototype.$logger.log(this.movies)
  }

  /**
   * 動画の切り替えを実行
   */
  private async setSelectedMovie(movieId: number) {
    Vue.prototype.$loading.start()
    // 前回の視聴履歴が存在する場合は完了させる
    await this.setCompleted()

    // 変更先の動画を取得
    const selectedMovie = this.movies.find((movie) => movie.movieId === movieId)
    if (!selectedMovie) {
      // nullブロック
      return
    }
    this.selectedMovieUrls = await this.loadLQUrls(selectedMovie.movieFilename)

    // 選択中の画質の動画が存在しない場合、高画質に切り替える
    if (this.notFoundCurrentQualityMovie()) this.currentMovieQuality = 'HQ'

    // 変則的だがonSelectedMovieChanged内でもloadingを行うため一度完了にする
    Vue.prototype.$loading.complete()

    // インスタンス変数へ代入し、onSelectedMovieChangedを走らせる
    this.selectedMovie = selectedMovie
  }

  /**
   * 選択中の画質の動画の存在チェック
   */
  private notFoundCurrentQualityMovie(): boolean {
    return (
      (this.currentMovieQuality == 'SQ' && !this.selectedMovieUrls.movieUrlSq) ||
      (this.currentMovieQuality == 'LQ' && !this.selectedMovieUrls.movieUrlLq)
    )
  }

  @Watch('selectedMovie')
  onSelectedMovieChanged(newData: MovieData, oldData: MovieData): void {
    if (oldData && newData.movieId === oldData.movieId) return

    Vue.prototype.$loading.start()
    try {
      this.createVideoPlayer()
    } catch (e) {
      if ((e as any).message == 'window.CreateVideoPlayer is not a function') {
        // 外部プレイヤー(oneTagPlayer.js)のロード状態によって、エラーが発生する場合がある為、時間をおいて再実行
        setTimeout(() => this.createVideoPlayer(), 2000)
      } else {
        throw new Error(e as string)
      }
    } finally {
      Vue.prototype.$loading.complete()
    }
  }

  // 画質変更によって動画URLが変更された場合はプレイヤーを作成しなおす
  onChangeMovieQuality(quality: 'HQ' | 'SQ' | 'LQ') {
    this.currentMovieQuality = quality
    this.createVideoPlayer()
  }

  // プレイヤー作成(url指定があれは優先。なけれは選択動画のデフォルトURLを使用する)
  private createVideoPlayer() {
    let url
    if (this.currentMovieQuality == 'LQ') url = this.selectedMovieUrls.movieUrlLq
    if (this.currentMovieQuality == 'SQ') url = this.selectedMovieUrls.movieUrlSq
    if (this.currentMovieQuality == 'HQ') url = this.selectedMovie?.movieUrl

    // @ts-ignore
    window.CreateVideoPlayer({
      target: 'player',
      src: url,
      auto: 'off',
      //movieid: this.selectedMovie.movieName,
    })
  }

  private isSelected(id: number): boolean {
    return id === this.selectedMovie?.movieId
  }

  private isCompleted(id: number): boolean {
    const movie = this.movies.find((movie) => movie.movieId === id)
    return movie ? movie.watched : false
  }

  private get likedColortype(): string {
    return this.selectedMovie?.liked ? 'skyblue' : 'gray'
  }

  private async setLiked() {
    if (this.resultMovie && this.selectedMovie) {
      this.selectedMovie.liked = !this.selectedMovie.liked
      // 視聴中のの動画視聴履歴のいいねを更新
      this.updateResultMovieLiked(this.resultMovie.resultMovieId, this.selectedMovie.liked)
    }
  }

  private async setCompleted() {
    if (this.resultMovie) {
      // 変数保持している動画データを視聴済みに変更
      const movie = this.movies.find((movie) => movie.movieId === this.resultMovie?.movieId)
      if (movie) {
        movie.watched = true
      }
      // 履歴を更新
      this.completeResultMovie(this.resultMovie.resultMovieId)
    }
  }

  private async doTransition() {
    await this.setCompleted()
    window.location.href = decodeURIComponent(this.historyBackUrl)
  }
}
